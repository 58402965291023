
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate, json } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Input, message, Tag, Modal, Form, Tooltip, Select } from 'antd';
import useTable from '../../hooks/table';
import Settings from '../../dependencies/custom/settings';
import useExcel from '../../hooks/excel';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import useBarChart from '../../hooks/barchart';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import useDrawer from '../../hooks/drawer';
import useModal from '../../hooks/modal';
import { View, Document, Page, Image, Text, PDFViewer, Font } from '@react-pdf/renderer';

const MarkHx = (props) => {
    const valuesStore = ValuesStore();
    const { filters, filterTypes } = utils.generateTableFilters();
    const navigate = useNavigate();
    // const keyOverrides = { categoryAlias: 'category' };
    const [sheetInfo, setSheetInfo] = useState({});
    const [scheme, setScheme] = useState([]);


    const [templateHeadings, setTemplateHeadings] = useState({ 'IndexNumber': 'student_id', 'Firstname': 'fname', 'Lastname': 'lname', 'Middlename': 'mname', 'HasRegistered': 'hasRegistered' });
    let sheetHeadings = { 'IndexNumber': '', 'Firstname': '', 'Lastname': '', 'Middlename': '', 'HasRegistered': '' };
    const [btnPermissions, setBtnPermissions] = useState({});

    // const [apiResponse, setApiResponse] = useState(undefined);
    const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
    const barchart = useBarChart();
    const [analysisDataTable, setAnalysisDataTable] = useState();
    const [analysisStatTable, setAnalysisStatTable] = useState();
    const printableAnalysis = useRef();
    const studentDetailsDrawer = useDrawer();


    // const modal = useModal();
    // const [uniqueColumnFromExcel, setUniqueColumnFromExcel] = useState(undefined);
    // const [uploadedExcelSheetHeadings, setUploadedExcelSheetHeadings] = useState();

    const resultPrintDrawer = useDrawer();
    const [schoolDetails, setSchoolDetails] = useState();
    const { state } = useLocation();


    const snWidth = 3;
    const indexWidth = 11;
    const namesWidth = 30;

    //and key value that points to the table names from zustand store.  
    const table = useTable({
        pagination: {
            current: 1,
            pageSize: 10,
            hideOnSinglePage: true,
            position: ['bottomRight'],
        },
        filters: { ...filters },
        filterTypes: { ...filterTypes }
    }, null, null, null, null, 'id', null, null);

    const excel = useExcel();

    let columns = ([
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-primary border-0' onClick={e => CustomFunctions.viewStudentBasicData(record, studentDetailsDrawer, valuesStore)}><i className='fas fa-eye' /></Button>
                </Space>
            },
        },
        {
            title: 'IndexNo.',
            dataIndex: 'student_id',
            ...table.getColumnSearchProps('student_id'),
        },
        {
            title: 'Firstname',
            dataIndex: 'fname',
            ...table.getColumnSearchProps('fname')
        },
        {
            title: 'Lastname',
            dataIndex: 'lname',
            ...table.getColumnSearchProps('lname')
        },
        {
            title: 'Middlename',
            dataIndex: 'mname',
            ...table.getColumnSearchProps('mname')
        },
        {
            title: 'Assessment',
            dataIndex: 'assessment',
        },
        {
            title: 'Exams',
            dataIndex: 'exams',
        }, {
            title: 'Total',
            dataIndex: 'total_obtained_marks'
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            ...table.getColumnSearchProps('grade')
        }
    ]);


    async function getGradeHx() {        
        let SQP = state;
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_grade_hx`, null, { ...SQP });
        setSheetInfo(SQP);
        table.setData(res.result);
        valuesStore.setValue('grade_hx', res.result);
    }


    function getGrades(programEduLevel) {
        return new Promise((resolve, reject) => {
            const timer = setInterval(() => {
                const grades = valuesStore.getValuesBy('grade', 'edu_level', programEduLevel);
                if (grades.length > 0) {
                    resolve(grades);
                    clearInterval(timer);
                }
            }, 500);

        });
    }

    function setGrade(obj, grades) {
        grades.forEach(v3 => {
            if (obj.total >= v3.marks_from && obj.total <= v3.marks_to) {
                obj['grade'] = v3.grade;
                obj['grade_points'] = v3.grade_points;
            }
        });
    }

    useMemo(() => {
        setTimeout(() => {

            getGradeHx();

            table.setColumns(columns);

            console.log('render');
            const permList = ['show_download_scoresheet_list',
                'show_download_scoresheet_template',
                'show_upload_scoresheet_template',
                'show_save_mark_button',
                'show_submit_mark_button',
                'show_approve_button',
                'show_publish_button',
                'show_unpublish_button',
                'show_release_sheet_button',
                'show_sheet_print_button'];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v, null, valuesStore);
                setBtnPermissions(r => ({ ...r, [v]: perm }))
            });


            const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
            if (institution) {
                setSchoolDetails(JSON.parse(institution));
            }
        }, 500);
    }, [sheetInfo?.submitted, valuesStore.getValue('marks'), templateHeadings]);



    function getSheetWithTranslatedHeading() {
        const d = valuesStore.getValue('grade_hx');
        const data = d.map(v => {
            let obj = {};
            for (let key in templateHeadings) {
                obj[key] = v[templateHeadings[key]];
            }
            obj = { ...obj, HasRegistered: 'Yes', Assessment: v?.assessment, Exams: v?.exams, Total: v?.total_obtained_marks, Grade: v?.grade };
            return obj;
        });
        return data;
    }

    function calcRemColsWidth(data) {
        const excpCols = ['Firstname', 'Lastname', 'Middlename', 'IndexNumber'];
        const keys = Object.keys(data);
        const length = keys?.length;
        const exceptionals = snWidth + indexWidth + namesWidth;
        let remainingCols = 0;
        for (let i = 0; i < length; i++) {
            const key = keys[i];
            if (!excpCols.includes(key)) {
                remainingCols += 1;
            }
        }
        const otherWidth = (100 - (exceptionals)) / remainingCols;
        return otherWidth;
    }

    function capitalizeFirst(word) {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    function pageNumber() {
        return <Text style={{
            position: 'absolute',
            fontSize: 12,
            bottom: 15,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        }} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed />;
    }

    function rows(data, otherColWidth) {
        return data?.map((v, index) => {
            delete v['HasRegistered'];
            let fname, sname, mname = '';
            const sn = <Text key={utils.generateUuid()} style={{ width: `${snWidth}%` }}>{(index + 1)}</Text>;
            for (let j in v) {
                if (j == 'Firstname') {
                    fname = capitalizeFirst(v[j]?.toLowerCase());
                } else if (j == 'Lastname') {
                    sname = v[j]?.toUpperCase();
                } else if (j == 'Middlename') {
                    mname = capitalizeFirst(v[j]?.toLowerCase());
                }
            }
            const names = `${sname}, ${fname} ${mname}`;
            const theNames = <Text key={utils.generateUuid()} style={{ width: `${namesWidth}%` }}>{names}</Text>;

            const indexNo = <Text key={utils.generateUuid()} style={{ width: `${indexWidth}%` }}>{v?.IndexNumber}</Text>;
            const grade = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{v?.Grade}</Text>;
            const exam = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{v?.Exams}</Text>;
            const CA = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{v?.Assessment}</Text>;
            const total = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{v?.Total}</Text>;

            return <View key={utils.generateUuid()} style={{ display: 'flex', flexDirection: 'row', fontSize: '9px', borderBottom: '1px solid grey' }}>
                {sn}{indexNo}{theNames}{CA}{exam}{total}{grade}
            </View>
        });
    }


    function firstRowHeader(data, otherColWidth) {
        delete data['HasRegistered'];
        const sn = <Text key={utils.generateUuid()} style={{ width: `${snWidth}%` }}>SN</Text>;
        const indexNo = <Text key={utils.generateUuid()} style={{ width: `${indexWidth}%` }}>IndexNumber</Text>;
        const names = <Text key={utils.generateUuid()} style={{ width: `${namesWidth}%` }}>Name</Text>;
        const grade = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>Grade</Text>;
        const exam = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>Exams</Text>;
        const CA = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>CA</Text>;

        const total = <Text key={utils.generateUuid()} style={{ width: `${otherColWidth}%` }}>{`Total`}</Text>;
        return <View key={utils.generateUuid()} style={{ display: 'flex', flexDirection: 'row', fontSize: '8px', backgroundColor: 'black', color: 'white', marginTop: '1px' }} fixed>
            {sn}{indexNo}{names}{CA}{exam}{total}{grade}
        </View>
    }

    function header() {
        return <>
            {/* The Water mark */}
            <View style={{ position: 'absolute', width: '100%' }} fixed>
                <Image style={{ opacity: '.7', height: '100vh' }} src={`${Settings.backend}/${schoolDetails?.transcriptWaterMarkImage}`} />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', border: 'solid black', borderBottom: '1px' }}>
                {/* logo */}
                <Image style={{ width: '13%', border: 'solid black', borderRight: '1px' }} src={`${Settings.backend}/${schoolDetails.logo}`} />
                {/* heading */}
                <View style={{ width: '82%', fontSize: '10pt', marginTop: '10px' }}>
                    <Text style={{ textAlign: 'center', fontSize: '15pt', fontWeight: 'bold' }}>{schoolDetails.name}</Text>
                    <Text style={{ textAlign: 'center' }}>{schoolDetails.pobox}</Text>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} >
                        <Text style={{ textAlign: 'right' }}>{schoolDetails.website}</Text>
                    </View>
                </View>
            </View>
        </>
    }

    function footer() {
        return <View key={utils.generateUuid()} style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', fontSize: '9px', justifyContent: 'space-between', marginTop: '3%' }}>
            <View style={{ display: 'flex', flexDirection: 'column' }} >
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                    <Text>H.O.D's Signature: </Text>
                    <Text style={{ width: '150px', borderBottom: '1px solid black' }} />
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '3%' }} >
                    <Text>Date: </Text>
                    <Text style={{ width: '150px', borderBottom: '1px solid black' }} />
                </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column' }} >
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                    <Text>Examiner's Signature: </Text>
                    <Text style={{ width: '150px', borderBottom: '1px solid black' }} />
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '3%' }} >
                    <Text>Date: </Text>
                    <Text style={{ width: '150px', borderBottom: '1px solid black' }} />
                </View>
            </View>
        </View>
    }

    function lecturerDetails() {
        return <View style={{ display: 'flex', flexDirection: 'column', fontSize: '9pt' }}>
            <Text>Course: {sheetInfo?.course_id}/{sheetInfo?.course_name}/{sheetInfo?.short_name}/{sheetInfo?.session}</Text>
            <Text>Semester: {sheetInfo?.semester_id}</Text>
            <Text>Academic Year: {sheetInfo?.acad_year}</Text>
            <Text>Lecturer: {sheetInfo?.lecturer_id}</Text>
        </View>

    }


    function printResults() {
        const data = getSheetWithTranslatedHeading();
        const grouped = utils.groupBy(data, 'HasRegistered');
        const reg = grouped['Yes'];
        const unreg = grouped['No'];
        const otherColWidth = calcRemColsWidth(reg[0]);
        const h = firstRowHeader(reg[0], otherColWidth);
        const regList = rows(reg, otherColWidth);
        const unRegList = rows(unreg, otherColWidth);
        const res = <PDFViewer width={'100%'} height={'700px'}>
            <Document style={{ margin: '20px' }}>
                <Page key={utils.generateUuid()} size="A4" style={{ padding: '20px', border: '1px solid black' }}>
                    {header()}
                    {lecturerDetails()}
                    {h}
                    {regList}
                    <Text style={{ color: 'red' }}>Unregistered Student List</Text>
                    {unRegList}
                    {footer()}
                    {pageNumber()}
                </Page>
            </Document>
        </PDFViewer>;
        resultPrintDrawer.setTitle('Print PDF Report');
        resultPrintDrawer.setWidth(800);
        resultPrintDrawer.setContent(res);
        resultPrintDrawer.setOpen(true);
        resultPrintDrawer.setPlacement('right');
    }

    function onDownloadSheet() {
        const data = getSheetWithTranslatedHeading();
        let headings = Object.keys(templateHeadings);
        headings.push('Assessment', 'Exams', 'Total', 'Grade');
        excel.exportXLSX(headings, data, 'sheet1', 'template.xlsx');
    }

    function onDownloadList() {
        const data = valuesStore.getValue('grade_hx');
        let obj = data.map(v => {
            sheetHeadings['IndexNumber'] = v?.student_id;
            sheetHeadings['Firstname'] = v?.fname;
            sheetHeadings['Lastname'] = v?.lname;
            sheetHeadings['Middlename'] = v?.mname;
            sheetHeadings['HasRegistered'] = v?.hasRegistered;
            return { ...sheetHeadings };
        });
        const headings = Object.keys(sheetHeadings);
        excel.exportXLSX(headings, obj, 'sheet1', 'file.xlsx');
    }



    // function approve() {
    //     CustomFunctions.changeSheetStatus([sheetInfo], 'approve');
    // }

    function publish() {
        CustomFunctions.changeSheetStatusExtra([sheetInfo], 'publish');
    }

    function unpublish() {
        CustomFunctions.changeSheetStatusExtra([sheetInfo], 'unpublish');
    }

    // function release() {
    //     CustomFunctions.changeSheetStatus([sheetInfo], 'release');
    // }
    
    // SELECT * FROM `scoresheet_summary_extra` where acad_year = '2022/2023' and course_id = 'mat352' and program_id ='pupe' and session ='fulltime' and semester_id = 5;

    function viewAnalysis() {
        const gradesForProgram = valuesStore.getValuesBy('grade', 'edu_level', sheetInfo.undergrad_postgrad);


        const marks = valuesStore.getValue('grade_hx');
        const totalRecords = marks.length;

        const grades = utils.groupBy(marks, 'grade');

        let chartLabels = [];
        let chartData = [];
        let percent = [];

        for (let i = 0; i < gradesForProgram.length; i++) {
            const grade = gradesForProgram[i]?.grade;
            chartLabels.push(grade);
            const count = grades[grade]?.length;
            const p = ((count / totalRecords) * 100)?.toFixed(2);
            chartData.push(count ? count : 0);
            percent.push(!isNaN(p) ? p : 0);
        }
        let allScores = [];
        marks.forEach(student => {
            allScores.push({ assessment: student?.assessment || 0, exams: student?.exams || 0, final: student?.total_obtained_marks || 0, sex: student?.sex });
        });
        const sex = utils.groupBy(allScores, 'sex');

        const avgAssessment = findAvg(sumArray(allScores, 'assessment'), totalRecords);
        const maxAssessment = findMax(allScores, 'assessment');
        const minAssessment = findMin(allScores, 'assessment');

        const avgExams = findAvg(sumArray(allScores, 'exams'), totalRecords);
        const maxExams = findMax(allScores, 'exams');
        const minExams = findMin(allScores, 'exams');

        const avgFinal = findAvg(sumArray(allScores, 'final'), totalRecords);
        const maxFinal = findMax(allScores, 'final');
        const minFinal = findMin(allScores, 'final');

        const maleAvg = findAvg(sumArray(sex?.Male, 'final'), sex?.Male?.length);
        const maleMax = findMax(sex?.Male, 'final');
        const maleMin = findMin(sex?.Male, 'final');

        const femaleAvg = findAvg(sumArray(sex?.Female, 'final'), sex?.Female?.length);
        const femaleMax = findMax(sex?.Female, 'final');
        const femaleMin = findMin(sex?.Female, 'final');


        const data = {
            labels: chartLabels,
            datasets: [
                {
                    label: 'Grade distribution chart',
                    data: chartData,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ],
        };
        barchart.setData(data);

        const theData = utils.getTable(undefined, [[<label className='fw-bold'>Grade</label>, ...chartLabels], [<label className='fw-bold'>Freq</label>, ...chartData], [<label className='fw-bold'>%</label>, ...percent]], 'table table-sm table-striped', 'headerclass', 'trClass', 'tdClass');
        setAnalysisDataTable(theData);

        const theStat = utils.getTable(
            ['', <label>Assessment</label>, <label>Examination</label>, <label>Final Score</label>, <label>Male</label>, <label>Female</label>],
            [
                [<label className='fw-bold'>Number</label>, 'N/A', 'N/A', 'N/A', sex?.Male?.length, sex?.Female?.length],
                [<label className='fw-bold'>Average Score</label>, avgAssessment, avgExams, avgFinal, maleAvg, femaleAvg],
                [<label className='fw-bold'>Maximum Score</label>, maxAssessment, maxExams, maxFinal, maleMax, femaleMax],
                [<label className='fw-bold'>Minimum Score</label>, minAssessment, minExams, minFinal, maleMin, femaleMin],
            ],
            'table table-sm table-striped', 'headerclass', 'trClass', 'tdClass');
        setAnalysisStatTable(theStat);
        setOpenAnalysisModal(true);
        barchart.setPlugins([barchart.allPlugins.ChartDataLabels]);
    }

    function sumArray(array, numberKey) {
        return array?.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[numberKey]), 0);
    }

    function findAvg(summation, totalCount) {
        const ans = (summation / totalCount).toFixed(2);
        return !isNaN(ans) ? ans : 0;
    }

    function findMax(array, numberKey) {
        return array?.sort((a, b) => b[numberKey] - a[numberKey])[0][numberKey];//order descending and choose the first item        
    }

    function findMin(array, numberKey) {
        return array?.sort((a, b) => a[numberKey] - b[numberKey])[0][numberKey];//order ascending and choose the first item
    }

    function tableDetails() {
        const data = valuesStore.getValue('grade_hx');
        let all = data?.length;


        return (
            <div>
                <Tooltip title='Go back'>
                    <Button onClick={() => { navigate('../course_mgt_extra') }} className='me-2 btn-outline-primary'><i style={{ cursor: 'pointer' }} className='fas fa-arrow-left' /></Button>
                </Tooltip>
                <label className='fw-bold text-primary me-2'>Total Count: {all}</label>
            </div>
        );
    }


    return (
        <>
            <div className='containerx'>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <div className="p-2 stylish-color text-white rounded fw-bold">
                            <div className="row row-cols-3">
                                <div className="col">
                                    Program: {sheetInfo?.short_name}<br />
                                    Course Code: {sheetInfo?.course_id}<br />
                                    Course Name: {sheetInfo?.course_name}<br />
                                </div>
                                <div className="col">
                                    Academic Year: {sheetInfo?.acad_year}<br />
                                    Semester: {sheetInfo?.semester_id}<br />
                                    Session: {sheetInfo?.session}
                                </div>
                                <div className="col">
                                    Lecturer: {sheetInfo?.lecturer_id}<br />
                                    Group Range :{sheetInfo?.group_from}-{sheetInfo?.group_to}<br />
                                    Status: {sheetInfo?.status}
                                </div>
                            </div>

                            <Space className='mt-3' wrap>
                                {btnPermissions?.show_publish_button && <Button onClick={e => publish()}><i className='fas fa-check-double  me-2' /> Publish</Button>}
                                {btnPermissions?.show_unpublish_button && <Button onClick={e => unpublish()}><i className='far fa-times-circle me-2' /> Unpublish </Button>}
                                {btnPermissions?.show_download_scoresheet_list && <Button onClick={e => onDownloadList()}><i className='fas fa-download me-2' /> Download List</Button>}
                                {btnPermissions?.show_download_scoresheet_list && <Button onClick={e => onDownloadSheet()}><i className='fas fa-download me-2' /> Download Scores</Button>}
                                {/* {btnPermissions?.show_sheet_print_button && <Button onClick={printResults} icon={<i className='fas fa-print me-1' />}>Print</Button>} */}
                                <Button onClick={printResults} icon={<i className='fas fa-print me-1' />}>Print</Button>
                                {btnPermissions?.show_save_mark_button && <Button onClick={viewAnalysis} icon={<i className='fas fa-chart-bar me-1' />}>Analysis</Button>}

                            </Space>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        {table.tableWithHeaderFooter(tableDetails, tableDetails)}
                    </div>
                </div>
            </div>



            <Modal width={1000} open={openAnalysisModal} footer={null} onCancel={e => setOpenAnalysisModal(false)}>
                <ReactToPrint
                    trigger={() => <Button size='large' className='btn-info border-0 ms-2'> <i className='fas fa-print me-2' />Print out</Button>}
                    content={() => printableAnalysis.current}
                />
                <div ref={printableAnalysis} className="container p-3">
                    <Space direction='vertical' className='d-flexx fw-bold mb-3'>
                        <label className='h4'>ASSESSMENT STATISTICS REPORT</label>
                        <label>Course: {sheetInfo?.course_id}/{sheetInfo?.course_name}/{sheetInfo?.short_name}/{sheetInfo?.session}</label>
                        <label>Semester: {sheetInfo?.semester_id}</label>
                        <label>Academic Year: {sheetInfo?.acad_year}</label>
                        <label>Lecturer: {sheetInfo?.lecturer_id}</label>
                    </Space>

                    <div className='row'>
                        <div className='mb-2 col-md-8'>
                            <label className='h5'>DATA</label>
                            {analysisDataTable}
                        </div>

                        <div className='col-md-8 mb-2'>
                            <label className='h5' >CHART</label>
                            {barchart.BarChart()}
                        </div>

                        <div className='col-md-12'>
                            <label className='h5'>STATISTICS</label>
                            {analysisStatTable}
                        </div>
                    </div>
                </div>
                <ReactToPrint
                    trigger={() => <Button size='large' className='btn-info border-0 ms-2'> <i className='fas fa-print me-2' />Print out</Button>}
                    content={() => printableAnalysis.current}
                />
            </Modal>
            {studentDetailsDrawer.drawerJSX(1021)}
            {resultPrintDrawer.drawerJSX(1021)}
        </>
    );
}

export default memo(MarkHx);