
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card, Watermark, Tag, Select, Tabs } from 'antd';
import Settings from '../../dependencies/custom/settings';
import ReactToPrint from 'react-to-print';
import CustomFunction from '../../dependencies/custom/custom-functions';
import useAdd from '../../hooks/add';
import { View, Document, Page, Image, Text, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';

// import MyWorker from '../../worker';
const Broadsheet = (props) => {
    const metaName = 'broadsheetFilters';
    const add = useAdd(metaName, 'table_name');
    const valuesStore = ValuesStore();
    const [records, setRecords] = useState([]);
    const [calculatedValues, setCalculatedValues] = useState([]);
    const [broadSheet, setBroadSheet] = useState([]);
    const [broadSheetWithGradesError, setBroadSheetWithGradesError] = useState([]);
    const [broadSheetWithIncompleteSems, setBroadSheetWithIncompleteSems] = useState([]);
    const [broadSheetWithCourseErrors, setBroadSheetWithCourseErrors] = useState([]);
    const [broadSheetWithDuplicateCourses, setBroadSheetWithDuplicateCourses] = useState([])
    const [indexNumber, setIndexNumber] = useState();
    const [totalSemesters, setTotalSemesters] = useState(0);
    const [cellsPerSem, setCellsPerSem] = useState(3);
    const [totalCounts, setTotalCounts] = useState({});
    const [loading, setLoading] = useState(false);
    const [schoolDetails, setSchoolDetails] = useState(
        {
            name: 'AKENTEN APPIAH-MENKAH UNIVERSITY OF SKILLS TRAINING AND ENTREPRENEURIAL DEVELOPMENT',
            office: 'Academic Affairs Office',
            pobox: 'P. O. Box 1277, Kumasi - Ghana',
            email: 'Email: registrar@aamusted.edu.gh',
            website: 'Website: aamusted.edu.gh'
        }
    );

    // Define style for table cells
    const style1 = {
        fontSize: '9pt',
    }

    const printable = useRef();

    function calcSems(startLevel, endLevel, semsInLevel) {
        const cnst = 100;
        const diff = parseInt((endLevel / cnst) - (startLevel / cnst)) + 1;
        return semsInLevel * diff;
    }

    function lastSem(record) {
        //sort record in descending order to get the last semester
        const last = record.sort((a, b) => b.semester_id - a.semester_id);
        return last[0]?.semester_id;
    }


    useMemo(() => {
        console.log('looping');
        broadsheetTemplate();
        genFilters();
    }, [calculatedValues]);

    function genFilters() {
        setTimeout(() => {
            valuesStore.setValue(metaName, [
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.start_acadyr', col_real_name: 'Start Academic Year', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT acadyr FROM acad_year WHERE archived=0","key":"acadyr","value":"acadyr"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.end_acadyr', col_real_name: 'End Academic Year', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT acadyr FROM acad_year WHERE archived=0","key":"acadyr","value":"acadyr"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.start_level', col_real_name: 'Start Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.end_level', col_real_name: 'End Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.session', col_real_name: 'Session', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT session,alias from sessions","key":"alias","value":"session"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'course_id', col_real_name: 'Course Code', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.status', col_real_name: 'Status', type: 'csvMultiSelect',
                    options: 'active,deferred,suspended,completed',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.batch', col_real_name: 'Batch', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT DISTINCT batch FROM students","key":"batch","value":"batch"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'semester_id', col_real_name: 'Semester', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT semester,alias from semester","key":"alias","value":"semester"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.program', col_real_name: 'Program', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from programs","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                }
            ]);
            add.setTblName('filters');
        }, 1000);
    }

    async function get() {
        setLoading(true);
        setCalculatedValues([]);
        setRecords([]);
        setBroadSheet([]);
        await CustomFunction.calc(setRecords, setCalculatedValues, { student_id: indexNumber?.join(), ...add.record });
        setLoading(false);
    }



    function findCoursePerSem(semesterCoursesLength) {
        let result = {};
        for (let sem in semesterCoursesLength || {}) {
            result[sem] = utils.mode(semesterCoursesLength[sem]);
        }
        return result;
    }

    function buildStats(values) {
        let highestSem = 0;
        let semesterCoursesLength = {};
        let highestSemCourse = {};
        values?.forEach((v, i) => {
            const length = Object.keys(v.semesters).length;
            highestSem = length > highestSem ? length : highestSem;
            for (let sem in v.semesters) {
                const courses = v?.semesters[sem]['courses'];
                const courseGrouped = utils.groupBy(courses, 'course_id');
                const coursesLength = Object.keys(courseGrouped).length;
                highestSemCourse[sem] = coursesLength > (highestSemCourse[sem] || 0) ? coursesLength : highestSemCourse[sem];
                if (semesterCoursesLength[sem]) {
                    semesterCoursesLength[sem]?.push(coursesLength);
                } else {
                    semesterCoursesLength[sem] = [coursesLength];
                }
            }
        });
        return { highestSem, semesterCoursesLength, highestSemCourse };
    }


    function classifyStudentClasses(student, designations, theLastSem, CLASSES) {
        for (let i of designations) {
            const from = i?.gpa_from;
            const to = i?.gpa_to;
            const desg = i?.designation;
            if (theLastSem?.CGPA >= from && theLastSem?.CGPA <= to) {
                CLASSES[desg]['list']?.push(student);
                break;
            }
        }
    }

    function processStudentGroupsBroadsheet(values) {
        // Return if no data provided
        if (!values?.length) return;
        
        // Build statistics from the provided data (i.e mode & max)
        const stats = buildStats(values);
        let highestSem = stats?.highestSem;
        let semesterCoursesLength = stats?.semesterCoursesLength;
        let coursesPerSem = findCoursePerSem(semesterCoursesLength);
        // let coursesPerSem = stats?.highestSemCourse;
        const eduLevel = values[0]?.eduLevel;
        let designations = valuesStore.getValuesBy('class_designation','edu_level',eduLevel);        
        const CLASSES = {};
        designations?.forEach(v => CLASSES[v?.designation] = { list: [] });
        values?.forEach((a) => {
            const sem1 = Object.keys(a.semesters).pop();//get the last semester            
            let lastSemIn1 = a?.semesters[sem1];//use last semester key to get its object            
            classifyStudentClasses(a, designations, lastSemIn1, CLASSES);
        });
        for (let key in CLASSES) {
            const vals = CLASSES[key]?.list;
            if (vals.length) {
                const pscb = processStudentClassBroadsheet(vals, highestSem, coursesPerSem);
                CLASSES[key] = { ...CLASSES[key], ...pscb };
            }
        }
        return CLASSES;
    }


    function processStudentClassBroadsheet(values, highestSem, coursesPerSem) {
        if (values.length <= 0) return;

        // Sort the data based on CGPA
        values?.sort((a, b) => {
            const sem1 = Object.keys(a.semesters).pop();//get the last semester
            const sem2 = Object.keys(b.semesters).pop();
            let lastSemIn1 = a?.semesters[sem1];//use last semester key to get its object
            let lastSemIn2 = b?.semesters[sem2];
            return lastSemIn2?.CGPA - lastSemIn1?.CGPA;
        });

        // Calculate total number of semesters
        const oneStudent = values[0];
        const expectedSems = 8;
        let sm = calcSems(oneStudent?.startLevel, oneStudent.endLevel, 2);
        const totalSemesters = sm < expectedSems ? ((expectedSems - sm) + sm) : sm;//add the difference if semester is less than the expectedSems       
        setTotalSemesters(totalSemesters);

        // Calculate total number of cells        
        const cells = totalSemesters * cellsPerSem;

        let data = [];
        let dataWithBadGradeErrors = [];
        let dataWithIncompleteSems = [];
        let dataWithCourseErrors = [];
        let dataWithDuplicateCourses = [];
        values?.forEach((v, i) => {//row i.e. a student is processed per each cycle
            //get name and index_no here
            const name = v.student_name;
            const index_no = v.index_no;
            const semesters = v.semesters;

            //checking those whose semester length are not up to the highest semester length
            let hasIncompleteSems = false;
            let missingSems = [];
            if (Object.keys(semesters).length != highestSem) {
                for (let sem = 1; sem <= highestSem; sem++) {
                    if (!semesters[sem]) {
                        missingSems.push(sem);
                    }
                }
                hasIncompleteSems = true;
            }

            const BIO = <tr key={`${index_no}_${i}`}>
                <td className='p-0 fw-bold' style={{ fontSize: '10pt' }} colSpan={cells}>{name} - {index_no}</td>
            </tr>;

            let subHeadings = [];
            for (let i = 0; i < totalSemesters; i++) {
                const a = (i + 1);
                if (missingSems.includes(a)) {
                    subHeadings.push(
                        <td style={style1} key={`${index_no}_${i}_cr`} className='p-0 text-danger'>CR{a}</td>,
                        <td style={style1} key={`${index_no}_${i}_gp`} className='p-0 text-danger'>GP{a}</td>,
                        <td style={style1} key={`${index_no}_${i}_gpa`} className='p-0 text-danger fw-bold'>GPA</td>);
                } else {
                    subHeadings.push(
                        <td style={style1} key={`${index_no}_${i}_cr`} className='p-0'>CR{(i + 1)}</td>,
                        <td style={style1} key={`${index_no}_${i}_gp`} className='p-0'>GP{(i + 1)}</td>,
                        <td style={style1} key={`${index_no}_${i}_gpa`} className='p-0 text-success fw-bold'>GPA</td>);
                }
            }
            const SH = <tr>{subHeadings}</tr>;

            let subData1 = [];
            let subData2 = [];
            //run through the semesters using the totalSemesters Variable          
            for (let i = 1; i <= totalSemesters; i++) {
                const CGV = semesters[i]?.CGV && parseFloat(semesters[i]?.CGV)?.toFixed(1);
                const CCR = semesters[i]?.CCR && parseFloat(semesters[i]?.CCR)?.toFixed(0);
                const CGPA = semesters[i]?.CGPA && parseFloat(semesters[i]?.CGPA)?.toFixed(2);

                const SGP = semesters[i]?.CGV && parseFloat(semesters[i]?.SGP)?.toFixed(1);
                const SCR = semesters[i]?.CCR && parseFloat(semesters[i]?.SCR)?.toFixed(0);
                const SGPA = semesters[i]?.CGPA && parseFloat(semesters[i]?.SGPA)?.toFixed(2);

                if (missingSems.includes(i)) {
                    subData1.push(
                        <td style={style1} key={`${index_no}_${i}_scr`} className='p-0 text-danger'>{SCR || 0}</td>,
                        <td style={style1} key={`${index_no}_${i}_sgv`} className='p-0 text-danger'>{SGP || 0}</td>,
                        <td style={style1} key={`${index_no}_${i}_sgpa`} className='p-0 text-danger fw-bold'>{SGPA || 0}</td>);
                    subData2.push(
                        <td style={style1} key={`${index_no}_${i}_ccr`} className='p-0 text-danger'>{CCR || 0}</td>,
                        <td style={style1} key={`${index_no}_${i}_cgv`} className='p-0 text-danger'>{CGV || 0}</td>,
                        <td style={style1} key={`${index_no}_${i}_cgpa`} className='p-0 text-danger fw-bold'>{CGPA || 0}</td>);
                } else {
                    subData1.push(
                        <td style={style1} key={`${index_no}_${i}_scr`} className='p-0'>{SCR || 0}</td>,
                        <td style={style1} key={`${index_no}_${i}_sgv`} className='p-0'>{SGP || 0}</td>,
                        <td style={style1} key={`${index_no}_${i}_sgpa`} className='p-0 text-success fw-bold'>{SGPA || 0}</td>);
                    subData2.push(
                        <td style={style1} key={`${index_no}_${i}_ccr`} className='p-0'>{CCR || 0}</td>,
                        <td style={style1} key={`${index_no}_${i}_cgv`} className='p-0'>{CGV || 0}</td>,
                        <td style={style1} key={`${index_no}_${i}_cgpa`} className='p-0 text-success fw-bold'>{CGPA || 0}</td>);
                }
            }
            const SD1 = <tr>{subData1}</tr>;
            const SD2 = <tr>{subData2}</tr>

            let badGradesErrors = [];
            let hasBadGradeErrors = false;
            let semHasCourseErrors = false;
            let semCoursesErrors = [];
            let coursesHasDuplicates = false;
            let duplicatesCourses = [];
            if (!hasIncompleteSems) {
                for (let i = 1; i <= totalSemesters; i++) {
                    let badGradesCoursesText = [];
                    let coursesErrors = [];
                    const courses = semesters[i]?.courses;

                    const coursesGrouped = utils.groupBy(courses || [], 'course_id');
                    let dupCourses = [];
                    for (let course in coursesGrouped) {
                        const c = coursesGrouped[course];
                        const credits = new Set();
                        if (c?.length != 1) {
                            for (let entry of c) {
                                credits.add(entry?.credit);
                            }
                        }
                        if (credits.size == 1) {
                            coursesHasDuplicates = true;
                            courses?.forEach((v, j) => {
                                dupCourses.push(<div className='fw-bold bg-dangerx text-danger' style={style1} key={utils.generateUuid()}>{v.course_id} - {v.grade}</div>);
                            });
                        }
                    }

                    if (coursesHasDuplicates) {
                        duplicatesCourses.push(
                            // create td with the duplicate course
                            <td style={style1} colSpan={cellsPerSem} key={utils.generateUuid()} className='p-0'>
                                {dupCourses}
                            </td>                            
                        );
                        continue;//skip to the next semester
                    } else {
                        duplicatesCourses.push(
                            // create td with nothing
                            <td style={style1} colSpan={cellsPerSem} key={utils.generateUuid()} className='p-0'></td>
                        );
                    }

                    if (courses?.length < coursesPerSem[i]) {
                        const details = `Expecting at least ${coursesPerSem[i]} courses`;
                        semHasCourseErrors = true;
                        courses?.forEach(v => {
                            coursesErrors.push(<div className='fw-bold bg-dangerx text-danger' style={style1} key={utils.generateUuid()}>{v.course_id} - {v.grade}</div>);
                        });

                        semCoursesErrors.push(
                            <td style={style1} colSpan={cellsPerSem} key={utils.generateUuid()} className='p-0'>
                                {coursesErrors}
                                <div style={{ width: '80px' }}>{details}</div>
                            </td>
                        );
                        continue;//skip to the next semester
                    } else {
                        semCoursesErrors.push(
                            <td style={style1} colSpan={cellsPerSem} key={utils.generateUuid()} className='p-0'></td>
                        );
                    }

                    courses?.forEach(v => {
                        if (v.grade === 'IC' || v.grade === 'X' || (v.grade === 'E' && !v.has_resat)) {
                            hasBadGradeErrors = true;
                            badGradesCoursesText.push(<div className='fw-bold bg-dangerx text-danger' style={style1} key={utils.generateUuid()}>{v.course_id} - {v.grade}</div>);
                        }
                    });
                    if (hasBadGradeErrors) {
                        badGradesErrors.push(<td style={style1} colSpan={cellsPerSem} key={utils.generateUuid()} className='p-0'>{badGradesCoursesText}</td>);
                    } else {
                        badGradesErrors.push(<td style={style1} colSpan={cellsPerSem} key={utils.generateUuid()} className='p-0'></td>);
                    }

                }
            }
            const ER1 = <tr>{semCoursesErrors}</tr>;
            const ER2 = <tr>{badGradesErrors}</tr>;
            const ER3 = <tr>{duplicatesCourses}</tr>;
            const tableClasses = 'mb-2 table table-striped';
            if (hasIncompleteSems) {
                const table = <table key={`${index_no}_incomplete_sem`} className={`${tableClasses}`}>
                    <tbody>
                        {/* {BIO}{HD}{SH}{SD1}{SD2} */}
                        {BIO}{SH}{SD1}{SD2}
                    </tbody>
                </table>;
                dataWithIncompleteSems.push(table);
            }
            if (coursesHasDuplicates) {
                const table = <table key={`${index_no}_dup_courses`} className={`${tableClasses}`}>
                    <tbody>
                        {/* {BIO}{HD}{SH}{SD1}{SD2}{ER3} */}
                        {BIO}{SH}{SD1}{SD2}{ER3}
                    </tbody>
                </table>;
                dataWithDuplicateCourses.push(table);
            }

            if (hasBadGradeErrors) {
                const table = <table key={`${index_no}_bad_grades`} className={`${tableClasses}`}>
                    <tbody>
                        {/* {BIO}{HD}{SH}{SD1}{SD2}{ER2} */}
                        {BIO}{SH}{SD1}{SD2}{ER2}
                    </tbody>
                </table>;
                dataWithBadGradeErrors.push(table);
            }

            if (semHasCourseErrors) {
                const table = <table key={`${index_no}_course_errors`} className={`${tableClasses}`}>
                    <tbody>
                        {/* {BIO}{HD}{SH}{SD1}{SD2}{ER1} */}
                        {BIO}{SH}{SD1}{SD2}{ER1}
                    </tbody>
                </table>;
                dataWithCourseErrors.push(table);
            }

            if (!hasIncompleteSems && !hasBadGradeErrors && !semHasCourseErrors && !coursesHasDuplicates) {
                const table = <table key={`${index_no}_good`} className={`${tableClasses}`}>
                    <tbody>
                        {/* {BIO}{HD}{SH}{SD1}{SD2} */}
                        {BIO}{SH}{SD1}{SD2}
                    </tbody>
                </table>;
                data.push(table);
            }

        });
        return { data, dataWithBadGradeErrors, dataWithIncompleteSems, dataWithCourseErrors, dataWithDuplicateCourses };
    }

    function broadsheetTemplate() {
        const values = calculatedValues;

        //GROUP STUDENTS BASE ON THEIR START_LEVEL;
        const studentGroups = utils.groupBy(values, 'startLevel');
        let dataAll = [];
        let dataWithBadGradeErrorsAll = [];
        let dataWithIncompleteSemsAll = [];
        let dataWithCourseErrorsAll = [];
        let dataWithDuplicateCoursesAll = [];
        let results = {};
        for (let startLevel in studentGroups) {
            const psgb = processStudentGroupsBroadsheet(studentGroups[startLevel]) || {};
            for (let key in psgb) {
                if (results[key]) {
                    results[key]?.push(psgb[key]);
                } else {
                    results[key] = [psgb[key]];
                }
            }
        }
        let mergedResults = {};
        for (let key in results) {
            const val = results[key];//the current class group
            mergedResults[key] = {};
            for (let entry of val) {
                for (let entryKey in entry) {
                    if (mergedResults[key][entryKey]) {
                        mergedResults[key][entryKey]?.push(...entry[entryKey]);
                    } else {
                        mergedResults[key][entryKey] = [...entry[entryKey]];
                    }
                }
            }
        }

        let designations = valuesStore.getValue('class_designation');
        designations?.sort((a, b) => a?.rank - b?.rank);
        const myTotalCount = { data: 0, dataWithBadGradeErrors: 0, dataWithCourseErrors: 0, dataWithDuplicateCourses: 0, dataWithIncompleteSems: 0 };
        for (let entry of designations || []) {
            const desg = entry?.designation;
            const group = mergedResults[desg];
            const cleanData = group?.data;
            const dataWithBadGradeErrors = group?.dataWithBadGradeErrors;
            const dataWithCourseErrors = group?.dataWithCourseErrors;
            const dataWithDuplicateCourses = group?.dataWithDuplicateCourses;
            const dataWithIncompleteSems = group?.dataWithIncompleteSems;

            const cld = cleanData?.length;
            const gr = dataWithBadGradeErrors?.length
            const cr = dataWithCourseErrors?.length;
            const dc = dataWithDuplicateCourses?.length;
            const ics = dataWithIncompleteSems?.length;
            const heading1 = cld > 0 ? semHeading(desg, cld) : '';
            const heading2 = gr > 0 ? semHeading(desg, gr) : '';
            const heading3 = cr > 0 ? semHeading(desg, cr) : '';
            const heading4 = dc > 0 ? semHeading(desg, dc) : '';
            const heading5 = ics > 0 ? semHeading(desg, ics) : '';

            myTotalCount['data'] += cld;
            myTotalCount['dataWithBadGradeErrors'] += gr;
            myTotalCount['dataWithCourseErrors'] += cr;
            myTotalCount['dataWithDuplicateCourses'] += dc;
            myTotalCount['dataWithIncompleteSems'] += ics;

            dataAll.push(heading1, cleanData);
            dataWithBadGradeErrorsAll.push(heading2, dataWithBadGradeErrors);
            dataWithIncompleteSemsAll.push(heading5, dataWithIncompleteSems);
            dataWithCourseErrorsAll.push(heading3, dataWithCourseErrors);
            dataWithDuplicateCoursesAll.push(heading4, dataWithDuplicateCourses);
        }
        setTotalCounts(myTotalCount);

        setBroadSheet(dataAll);
        setBroadSheetWithGradesError(dataWithBadGradeErrorsAll);
        setBroadSheetWithIncompleteSems(dataWithIncompleteSemsAll);
        setBroadSheetWithCourseErrors(dataWithCourseErrorsAll);
        setBroadSheetWithDuplicateCourses(dataWithDuplicateCoursesAll);
    }

    function handleChange(value) {
        setIndexNumber(value);
    }

    function semHeading(title, count) {
        let headings = [];
        for (let i = 0; i < totalSemesters; i++) {
            headings.push(<td style={style1} colSpan={cellsPerSem} key={utils.generateUuid()} className='p-0 text-white bg-dark'>Semester{(i + 1)}</td>);
        }
        const HD =
            <div className='mt-1' key={utils.generateUuid()}>
                <label className='h6 text-danger'>{title}, Total Count = {count}</label>
                <table className='table table-striped m-0 p-0'>
                    <tbody >
                        <tr>
                            {headings}
                        </tr>
                    </tbody>
                </table>
            </div>
        return HD;
    }

    const items = [
        {
            key: '1',
            label: `Broadsheet without errors`,
            children: <>
                <label className='h5 fw-bold'>Total Records: {totalCounts?.data || 0}</label>
                {broadSheet}
            </>,
        },
        {
            key: '2',
            label: `Broadsheet with Grade errors`,
            children: <>
                <label className='h5 fw-bold'>Total Records: {totalCounts?.dataWithBadGradeErrors || 0}</label>
                {broadSheetWithGradesError}
            </>
        },
        {
            key: '3',
            label: `Broadsheet with incomplete semesters`,
            children: <>
                <label className='h5 fw-bold'>Total Records: {totalCounts?.dataWithIncompleteSems || 0}</label>
                {broadSheetWithIncompleteSems}
            </>
        },
        {
            key: '4',
            label: `Broadsheet with course errors`,
            children: <>
                <label className='h5 fw-bold'>Total Records: {totalCounts?.dataWithCourseErrors || 0}</label>
                {broadSheetWithCourseErrors}
            </>
        },
        {
            key: '5',
            label: `Broadsheet with duplicate courses & grades`,
            children: <>
                <label className='h5 fw-bold'>Total Records: {totalCounts?.dataWithDuplicateCourses || 0}</label>
                {broadSheetWithDuplicateCourses}
            </>
        }

    ];

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <Card
                    bordered={false}
                    className="criclebox tablespace border-0 mb-24 p-2"
                    title="Broadsheet"
                    extra={
                        <Space>
                            <Button loading={loading} className='ms-2 btn-success border-0' onClick={e => get()}><i className='fas fa-paper-plane me-2' />QUERY</Button>
                            <ReactToPrint
                                trigger={() => <Button className='btn-primary border-0'><i className='fas fa-print me-1' />Print out</Button>}
                                content={() => printable.current}
                            />
                        </Space>
                    }
                >
                    <div className='mt-2'>
                        <span className="">Index Number(s)</span>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            tokenSeparators={[',']}
                            className='mb-2'
                            onChange={handleChange}
                            placeholder='Enter Index Number(s)'
                        />
                    </div>
                    <div className='row row-cols-2'>
                        {add?.form?.map((v, i) => v && <div key={i}>{v}</div>)}
                    </div>


                    <div className='row px-2 mt-2' ref={printable}>
                        <div className='col-md-12'>
                            <div className='d-flex'>
                                <div className='border-end'>
                                    <img src={`${Settings.backend}/fav.png`} width={170} height={120} />
                                </div>
                                <div className='w-100'>
                                    <label className='h4 text-center'>AKENTEN APPIAH-MENKAH UNIVERSITY OF SKILLS TRAINING AND ENTREPRENEURIAL DEVELOPMENT</label>
                                    <center className='h5 fw-bold'>BROADSHEET </center>
                                    <label className='ms-2'>
                                        {Object.keys(add.record)?.map((v,i) => {
                                            if (add.record[v]) {                                                
                                                const f = valuesStore.getValuesBy(metaName, 'table_name', 'filters');
                                                const filterName = f?.filter(j => j?.column_name == v);
                                                return `${filterName.length && filterName[0]?.col_real_name} : ${add.record[v]} | `;
                                            } else {
                                                return '';
                                            }
                                        })}
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className='col-md-12'>
                            <Tabs defaultActiveKey="1" items={items} />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default memo(Broadsheet);