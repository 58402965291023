
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card, Tag, Divider, Radio, message } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import Settings from '../../dependencies/custom/settings';


const ScoreSheetSummary = (props) => {
    const valuesStore = ValuesStore();
    const meta = 'other_metadata';
    const tbl = 'table_name';
    const add = useAdd(meta, tbl);
    const { filters, filterTypes } = utils.generateTableFilters();

    const [gradeType, setGradeType] = useState('all');

    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,//`${Settings.backend}/get_scoresheet_summary`,
        'post',
        'summary',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: '', fields: ['*'] });


    const columns = ([
        {
            title: 'IndexNo.',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no'),
        },
        {
            title: 'Firstname',
            dataIndex: 'fname',
            ...table.getColumnSearchProps('fname'),
        },
        {
            title: 'Lastname',
            dataIndex: 'lname',
            ...table.getColumnSearchProps('lname'),
        },
        {
            title: 'Middlename',
            dataIndex: 'mname',
            ...table.getColumnSearchProps('mname'),
        },
        {
            title: 'Course',
            dataIndex: 'course_code',
            filterSearch: true,
            render: (v, record) => record.course_name
        },
        {
            title: 'AcadYr',
            dataIndex: 'acad_year',
            ...table.getColumnSearchProps('acad_year'),
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            filterSearch: true,
        },
        {
            title: 'Marks',
            dataIndex: 'total_obtained_marks',
        },
        {
            title: 'Has Resat',
            dataIndex: 'has_resat',
            render: (v, record) => v ? <Tag color={'green-inverse'}>Yes</Tag> : <Tag color={'blue-inverse'}>No</Tag>
        }
    ]);

    const colFilters = [
        {
            filter: 'course_code',
            sql: "SELECT course_name,course_code FROM courses",
            key: 'course_code',
            value: 'course_name'
        },
        {
            filter: 'grade',
            sql: 'SELECT DISTINCT grade FROM grade',
            key: 'grade',
            value: 'grade'
        }
    ];



    async function resitAssignment() {
        const records = table.selectedRows;
        if (records.length <= 0) {
            utils.showNotification(undefined, 'No record selected');
            return;
        }

        const firstGrade = records[0].grade;
        let homogenous = true;
        for (let i = 0; i < records.length; i++) {
            if (records[i].grade !== firstGrade) {
                homogenous = false;
                break;
            }
        }

        if (!homogenous) {
            utils.showNotification(undefined, 'Grades of selected students are not homogeneous');
            return;
        }
        if (!add.record.course) {
            utils.showNotification(undefined, 'Please select a course');
            return;
        }
        if (!add.record.lecturer_id) {
            utils.showNotification(undefined, 'Please select a lecturer');
            return;
        }

        const data = { ...add.record, records: records, gradeType: firstGrade };
        // console.log(data, gradeType,homogenous);

        let res = await utils.requestWithReauth('post', `${Settings.backend}/resit_assignment`, null, data);

        if (res.status === 'Ok') {
            utils.showNotification(undefined, 'Operation successful', 'text-success');
            table.setSelectedRows([]);
            table.rowSelectionDebug();
            add.setRecord([]);
        } else {
            utils.showNotification(undefined, res.msg);
        }

        // table.setRowSelectionObject({ ...table.rowSelectionObject, selectedRowKeys: table.selectedRowKeys })
        // table.setSelectedRowKeys([]);
        // add.setRecord({ assign_by: 'K3050', submitted: '0', mounted_course_id: record['custom_id'] });
        // add.setSqlPlaceHolders({ 'placeholder1': 'K3050' });

        // add.setTblName(tableName);
        // add.setFields(['lecturer_id', 'date_inserted'])
        // add.setShowModal(true);
        // add.setSaveCompleted(false);
        // add.setChildrenTop(
        //     <>
        //         <center><u><h6 className='fw-bold'>Preview List</h6></u></center>
        //         {previewTable.table}
        //     </>
        // );
    }

    function getGradeType(type) {
        setGradeType(type)
        table.setExtraFetchParams({ gradeType: type });
        table.setSelectedRows([]);
        table.rowSelectionDebug();
        // get();
    }


    useMemo(() => {
        setTimeout(() => {
            valuesStore.setValue(meta, [
                {
                    id: '', table_name: 'resit_ic',
                    column_name: 'course', col_real_name: 'Course', type: 'sqlSelect',
                    options: '{"sql":"SELECT course_name,course_code FROM courses","key":"course_code","value":"course_code,course_name"}',
                    extra_options: '',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 2
                },
                {
                    id: '', table_name: 'resit_ic',
                    column_name: 'department', col_real_name: 'Department', type: 'sqlSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type IN (5)","key":"alias","value":"name"}',
                    extra_options: `{"targets":[{"target":"lecturer_id","sql":"SELECT staff_id,name FROM admin,admin_dept WHERE admin.staff_id = admin_dept.admin_id AND dept='this.value'","key":"staff_id","value":"staff_id,name"}]}`,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 2
                },
                {
                    id: '', table_name: 'resit_ic',
                    column_name: 'lecturer_id', col_real_name: 'Lecturer', type: 'sqlSelect',
                    options: '{"sql":"SELECT name,staff_id FROM admin WHERE 0","key":"staff_id","value":"staff_id,name"}',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 2
                }
            ]);
            add.setTblName('resit_ic');
        }, 1000);

        table.setAllowSelection(true);
        table.setRowSelectionObject({
            ...table.rowSelectionObject,
            getCheckboxProps: (record) => {
                return {
                    //disable selection if it doesn't include E or IC
                    disabled: !(['E', 'IC'].includes(record.grade)) || record.has_resat || record.status !== 'published'
                }
            }
        });
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);

        //change in the add hook causes this component to rerender      
        if (add.record?.course && add.whichElementChanged === 'course') {
            table.rowSelectionDebug();
            table.setSelectedRows([]);
            //inform the table hook about the course_code filter 
            filters['course_code'] = [add.record?.course];
            table.setTableParams({ ...table.tableParams, filters: { ...filters } });
        }
        get();
    }, [add.saveCompleted, add.record]);

    function get() {
        if (!Object.keys(add.record).length) {
            utils.showNotification(undefined, 'Please select a course');
            return;
        }
        table.setDataURL(`${Settings.backend}/get_scoresheet_summary`);
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <Card
                    bordered={false}
                    className="criclebox tablespace border-0 mb-24 p-2"
                    title="Grades summary"
                    extra={
                        <Space>
                            <Button className='btn-info border-0' onClick={e => resitAssignment()}><i className='fas fa-user-plus me-2' />Assign Resit/IC</Button>
                            <Radio.Group>
                                <Radio.Button className='btn-outline-info' onClick={e => getGradeType('resit')} value="resit"><i className='fas fa-user-plus me-2' /> Resit</Radio.Button>
                                <Radio.Button className='btn-outline-info' onClick={e => getGradeType('ic')} value="ic"><i className='fas fa-user-plus me-2' /> IC</Radio.Button>
                                <Radio.Button className='btn-outline-info' onClick={e => getGradeType('all')} value="all"><i className='fas fa-filter me-2' /> Show All</Radio.Button>
                            </Radio.Group>                                                        
                        </Space>
                    }
                >
                    <div className='row'>
                        <div className='col-md-12 mb-2'>
                            <Divider orientation="left" plain>Resit/IC Assignment Form</Divider>
                            <div className='mt-2'>
                                <div className='d-flex flex-column'>
                                    <Space direction='vertical' className='w-100'>
                                        <div className='col-md-6'>{add.form}</div>
                                    </Space>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <label className='h6'>Result for {gradeType}</label>
                            {table.tableWithHeaderFooter()}
                        </div>
                    </div>
                </Card>
            </div>

            {/* {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)} */}
        </>
    );
}

export default memo(ScoreSheetSummary);