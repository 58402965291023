
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card, Input, Empty, Form, Tabs, Select } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useDynamicForm from '../../hooks/dynamic_form';
import useUpload from '../../hooks/upload';
import useExcel from '../../hooks/excel';
import Utilities from '../../dependencies/custom/react-utilities';

const ExtraGradeCorrections = (props) => {
    const valuesStore = ValuesStore();    
    const metaName = 'extra_grade_form';
    const add = useAdd(metaName, 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    const [indexNo, setIndexNo] = useState();
    const [courseCode, setCourseCode] = useState();
    const [grade, setGrade] = useState({});
    const [studentDetails, setStudentDetails] = useState({});
    const [result, setResult] = useState([]);
    const [errors, setErrors] = useState([]);

    const dynamicFormName = 'gradeChange';
    const dynamicForm = useDynamicForm(dynamicFormName, null, { text: 'Submit', type: 'primary', classes: 'btn-success border-0' }, null, false);
    const upload = useUpload()
    const excel = useExcel();
    const [preferredHeadings, setPreferredHeadings] = useState(['IndexNumber', 'Assessment', 'Exams']);
    let assessment = 0;
    let exams = 0;
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight', 'topRight']
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: '', fields: [''] });


    const tableWithErrors = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight', 'topRight']
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: '', fields: [''] });

    const rejectedList = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight', 'topRight']
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        'post',
        'result',
        'totalCount',
        'IndexNumber',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: '', fields: [''] });


    const columns =
        ([
            {
                title: 'Index Number',
                dataIndex: 'IndexNumber',
                ...table.getColumnSearchProps('IndexNumber'),
            },
            {
                title: `Assessment`,
                dataIndex: 'Assessment',
            },
            {
                title: `Exams`,
                dataIndex: 'Exams',
            },
        ]);


    const columnsWithErrors = (
        [
            {
                title: 'Index Number',
                dataIndex: 'IndexNumber',
                ...table.getColumnSearchProps('IndexNumber'),
            },
            {
                title: `Assessment`,
                dataIndex: 'Assessment',
            },
            {
                title: `Exams`,
                dataIndex: 'Exams',
            },
            {
                title: 'Error',
                dataIndex: 'error',
                render(v, record) {
                    return <label className='text-danger'>{v}</label>
                }
            },
        ]);


    async function queryExtraGrade() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_extra_grade`, null, { indexNo, courseCode });
        if (res.status == "Ok") {
            const grade = res?.grade?.length ? res?.grade[0] : {};
            const student = res?.studentDetails?.length ? res?.studentDetails[0] : {};
            setStudentDetails(student);
            setGrade(grade);                    
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }

    function totalMarksGrade(total, grades) {
        for (let v3 of grades) {
            if (total >= v3.marks_from && total <= v3.marks_to) {
                const grade = v3.grade;
                const gp = v3.grade_points;
                return { grade, gp };
            }
        };
        return {};
    }

    function genSelectOptFromStore(storeKey, valueKey, labelKey) {
        const s = valuesStore.getValue(storeKey);
        return s?.map((v) => {
            return { value: v?.[valueKey], label: v?.[labelKey] };
        });
    }

    async function extraGradeAddRequest(type) {
        const mlp = await getMarkAllocationPolicy();
        const semesters = genSelectOptFromStore('semester', 'alias', 'semester');
        const acadyr = genSelectOptFromStore('acad_year', 'acadyr', 'acadyr');
        const gradingSystem = valuesStore.getValuesBy('grade', 'edu_level', studentDetails?.undergrad_postgrad);
        dynamicForm.setModalTitle(`New grade for ${studentDetails?.index_no}`);
        dynamicForm.setFormChildren(
            <div>
                <Form.Item
                    key={'assessment'}
                    name={'assessment'}
                    label={'Assessment'}
                    rules={[
                        {
                            required: true,
                            async validator(object, value) {
                                assessment = value;
                                const t = parseFloat(exams) + parseFloat(assessment);
                                const gs = totalMarksGrade(t, gradingSystem);
                                dynamicForm.form.setFieldsValue({ total_obtained_marks: t, grade: gs.grade, grade_points: gs.gp });
                                if (!value || parseFloat(value) > parseFloat(mlp?.internal) || parseFloat(value) < 0) {
                                    return Promise.reject(new Error('Assessment is required and cannot go above or below the allocated marks'));
                                }
                            }
                        },
                        {
                            required: true,
                        }
                    ]}>
                    <Input type={'number'} />
                </Form.Item>
                <Form.Item
                    key={'exams'}
                    name={'exams'}
                    label={'Exams'}
                    rules={[
                        {
                            required: true,
                            async validator(object, value) {
                                exams = value;
                                const t = parseFloat(exams) + parseFloat(assessment);
                                const gs = totalMarksGrade(t, gradingSystem);
                                dynamicForm.form.setFieldsValue({ total_obtained_marks: t, grade: gs.grade, grade_points: gs.gp });
                                if (!value || parseFloat(value) > parseFloat(mlp?.external) || parseFloat(value) < 0) {
                                    return Promise.reject(new Error('Exams is required and cannot go above or below the allocated marks'));
                                }
                            }
                        }
                    ]}>
                    <Input type={'number'} />
                </Form.Item>
                <Form.Item
                    key={'acad_year'}
                    name={'acad_year'}
                    label={'Academic Year'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}>

                    <Select
                        placeholder={`Select academic year`}
                        className='bd-highlightx flex-grow-1'
                        options={acadyr}
                        showSearch
                    />
                </Form.Item>
                <Form.Item
                    key={'semester'}
                    name={'semester'}
                    label={'Semester'}
                    rules={[
                        {
                            required: true,
                        }
                    ]}
                >
                    <Select
                        placeholder={`Select semester`}
                        className='bd-highlightx flex-grow-1'
                        options={semesters}
                        showSearch
                    />
                </Form.Item>
                <Form.Item
                    key={'total_obtained_marks'}
                    name={'total_obtained_marks'}
                    label={'Total Obtained Marks'}
                >
                    <Input type={'number'} disabled />
                </Form.Item>

                <Form.Item
                    key={'grade'}
                    name={'grade'}
                    label={'Grade'}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    key={'grade_points'}
                    name={'grade_points'}
                    label={'Grade Point'}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    key={'remarks'}
                    name={'remarks'}
                    label={'Remarks'}
                >
                    <Input.TextArea placeholder="Remarks..." autoSize />
                </Form.Item>
                <Form.Item
                    key={'studentID'}
                    name={'studentID'}
                    label={'student ID'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>

                <Form.Item
                    key={'type'}
                    name={'type'}
                    label={'Type'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>
                <Form.Item
                    key={'program'}
                    name={'program'}
                    label={'program'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>
                <Form.Item
                    key={'course'}
                    name={'course'}
                    label={'course'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>
                <Form.Item
                    key={'session'}
                    name={'session'}
                    label={'session'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>
            </div>
        );
        const total = parseFloat(grade.assessment) + parseFloat(grade.exams);
        const gs = totalMarksGrade(total, gradingSystem);
        dynamicForm.form.setFieldsValue({ session: studentDetails?.session, course: courseCode, type, program: studentDetails?.program, studentID: studentDetails?.index_no, assessment: grade?.assessment, exams: grade?.exams, total_obtained_marks: total, grade: gs.grade, grade_points: gs.gp });
        dynamicForm.setShowModal(true);
    }

    async function extraGradeChangeRequest(type) {
        const mlp = await getMarkAllocationPolicy();
        const gradingSystem = valuesStore.getValuesBy('grade', 'edu_level', studentDetails?.undergrad_postgrad);
        dynamicForm.setModalTitle(`Grade change for ${studentDetails?.index_no}`);
        dynamicForm.setFormChildren(
            <div>
                <label className='fw-bold text-danger'>Type: {type}</label>
                <Form.Item
                    key={'assessment'}
                    name={'assessment'}
                    label={'Assessment'}
                    rules={[
                        {
                            required: true,
                            async validator(object, value) {
                                assessment = value;
                                const t = parseFloat(exams) + parseFloat(assessment);
                                const gs = totalMarksGrade(t, gradingSystem);
                                dynamicForm.form.setFieldsValue({ total_obtained_marks: t, grade: gs.grade, grade_points: gs.gp });
                                if (!value || parseFloat(value) > parseFloat(mlp?.internal) || parseFloat(value) < 0) {
                                    return Promise.reject(new Error('Assessment is required and cannot go above or below the allocated marks'));
                                }
                            }
                        },
                        {
                            required: true,
                        }
                    ]}>
                    <Input type={'number'} />
                </Form.Item>
                <Form.Item
                    key={'exams'}
                    name={'exams'}
                    label={'Exams'}
                    rules={[
                        {
                            required: true,
                            async validator(object, value) {
                                exams = value;
                                const t = parseFloat(exams) + parseFloat(assessment);
                                const gs = totalMarksGrade(t, gradingSystem);
                                dynamicForm.form.setFieldsValue({ total_obtained_marks: t, grade: gs.grade, grade_points: gs.gp });
                                if (!value || parseFloat(value) > parseFloat(mlp?.external) || parseFloat(value) < 0) {
                                    return Promise.reject(new Error('Exams is required and cannot go above or below the allocated marks'));
                                }
                            }
                        }
                    ]}>
                    <Input type={'number'} />
                </Form.Item>
                <Form.Item
                    key={'total_obtained_marks'}
                    name={'total_obtained_marks'}
                    label={'Total Obtained Marks'}
                >
                    <Input type={'number'} disabled />
                </Form.Item>

                <Form.Item
                    key={'grade'}
                    name={'grade'}
                    label={'Grade'}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    key={'grade_points'}
                    name={'grade_points'}
                    label={'Grade Point'}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    key={'remarks'}
                    name={'remarks'}
                    label={'Remarks'}
                >
                    <Input.TextArea placeholder="Remarks..." autoSize />
                </Form.Item>
                <Form.Item
                    key={'studentID'}
                    name={'studentID'}
                    label={'student ID'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>
                <Form.Item
                    key={'prev_grade_id'}
                    name={'prev_grade_id'}
                    label={'Previous Grade ID'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>
                <Form.Item
                    key={'type'}
                    name={'type'}
                    label={'Type'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>
                <Form.Item
                    key={'program'}
                    name={'program'}
                    label={'program'}
                    className='d-none'
                >
                    <Input hidden />
                </Form.Item>
            </div>
        );
        const total = parseFloat(grade.assessment) + parseFloat(grade.exams);
        const gs = totalMarksGrade(total, gradingSystem);
        // console.log(studentDetails)
        dynamicForm.form.setFieldsValue({ program: studentDetails?.program, type, studentID: studentDetails?.index_no, prev_grade_id: grade?.id, assessment: grade?.assessment, exams: grade?.exams, total_obtained_marks: total, grade: gs.grade, grade_points: gs.gp });
        dynamicForm.setShowModal(true);
    }

    function gradeChangeSubmit() {
        dynamicForm.setFormSubmit({
            async onFormSubmit(values) {
                // console.log(values);
                // return;
                delete values[dynamicFormName];
                let res = await utils.requestWithReauth('post', `${Settings.backend}/save_grade_change_request_extra`, null, values);
                if (res.status === 'Ok') {
                    utils.showNotification(undefined, 'Operation successfull', 'text-success');
                    dynamicForm.setShowModal(false);
                } else {
                    utils.showNotification(undefined, res.msg);
                }
            }
        });
    }


    function getMarkAllocationPolicy() {
        return new Promise((resolve, reject) => {
            const timer = setInterval(() => {
                const mlp = valuesStore.getValuesBy('settings', 'prop', 'marksAllocationPolicy');
                if (mlp.length > 0) {
                    resolve(JSON.parse(mlp[0]?.value));
                    clearInterval(timer);
                }
            }, 500);
        });
    }

    function straightUpload() {
        upload.setCustomBeforeUpload({
            async beforeUpload(file, acceptedFileTypes) {
                if (!acceptedFileTypes.includes(file?.type)) {
                    utils.showNotification(undefined, 'File type not allowed', 'text-danger');
                    return;
                }

                const result = await excel.importXLSX(file);
                if (!result.length) {
                    utils.showNotification(undefined, 'File does not contain data', 'text-danger');
                    return;
                }
                //VALIDATING HEADINGS/COLUMNS
                const headings = Object.keys(result[0]);
                if (headings.length != 3) {
                    utils.showNotification(undefined, 'Expecting only 3 columns [IndexNumber,Assessment and Exams]', 'text-danger');
                    return;
                } else {
                    for (let h of headings) {
                        if (!['IndexNumber', 'Assessment', 'Exams']?.includes(h)) {
                            utils.showNotification(undefined, `Unrecognized heading (${h})`, 'text-danger');
                            return;
                        }
                    }
                }
                //VALIDATING MARKS USING MARKS ALLOCATION POLICY
                // const mlp = JSON.parse(valuesStore.getValuesBy('settings', 'prop', 'marksAllocationPolicy')[0]?.value);
                const mlp = await getMarkAllocationPolicy();
                const intnl = parseFloat(mlp?.internal);
                const extnl = parseFloat(mlp?.external);

                let index = 2;//data on excel sheet begins on row 2
                let withErrors = [];
                let withoutErrors = [];
                for (let r of result || []) {
                    const assessment = parseFloat(r?.Assessment);
                    const exams = parseFloat(r?.Exams);
                    if (exams > extnl || exams < 0) {
                        withErrors.push({ ...r, index, error: `Invalid exams score at row ${index} on excel sheet`, id: utils.generateUuid() });
                    }
                    if (assessment > intnl || assessment < 0) {
                        withErrors.push({ ...r, index, error: `Invalid assessement score at row ${index} on excel sheet`, id: utils.generateUuid(), });
                    }
                    if (exams >= 0 && exams <= extnl && assessment >= 0 && assessment <= intnl) {
                        withoutErrors.push({ id: utils.generateUuid(), ...r });
                    }
                    index += 1;
                }
                //CHECKING FOR DUPLICATE INDEX NUMBERS
                let tracker = {};
                let duplicates = [];
                let clean = []
                for (let data of withoutErrors || []) {
                    if (data['IndexNumber'] in tracker) {
                        duplicates.push({ ...data, error: `Duplicate index number found for ${data['IndexNumber']}`, id: utils.generateUuid(), });
                    } else {
                        clean.push(data);
                        tracker[data['IndexNumber']] = data;
                    }
                }
                //USING DUPLICATES TO FIND CORRESPONDING RECORD IN CLEAN DATA 
                let final = [];
                let finalDup = [];
                for (let c of clean || []) {
                    const indexInClean = c['IndexNumber'];
                    for (let d of duplicates || []) {
                        const indexInDup = d['IndexNumber'];
                        if (indexInClean == indexInDup) {
                            finalDup.push(d);
                        } else {
                            final.push(c);
                        }
                    }
                }

                const finalData = final.length ? final : clean;
                setResult(finalData);
                setErrors([...withErrors, ...finalDup]);

                table.setData(finalData);
                tableWithErrors.setData([...withErrors, ...finalDup]);
                utils.showNotification(undefined, 'File upload successful', 'text-success');
            }
        });
    }

    function genForm() {
        setTimeout(() => {
            const extra_grade_form = 'extra_grade_form'
            valuesStore.setValue(metaName, [
                {
                    id: '', table_name: extra_grade_form,
                    column_name: 'session', col_real_name: 'Session', type: 'sqlRadio',
                    options: '{"sql":"SELECT session,alias from sessions","key":"alias","value":"session"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: extra_grade_form,
                    column_name: 'course_id', col_real_name: 'Course Code', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: extra_grade_form,
                    column_name: 'acad_year', col_real_name: 'Acad. Yr.', type: 'sqlSelect',
                    options: '{"sql":"SELECT acadyr FROM acad_year","key":"acadyr","value":"acadyr"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: extra_grade_form,
                    column_name: 'semester', col_real_name: 'Semester', type: 'sqlSelect',
                    options: '{"sql":"SELECT semester,alias from semester","key":"alias","value":"semester"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: extra_grade_form,
                    column_name: 'program', col_real_name: 'Program', type: 'sqlSelect',
                    options: '{"sql":"SELECT short_name,alias from programs WHERE show_hide=1","key":"alias","value":"short_name"}',
                    backend_visible: 1,
                    rank: 1
                }
            ]);
            add.setTblName(extra_grade_form);
        }, 1000);
    }


    function appendMarksPolicyToTableHeading(columns, targetColumn, policy) {
        return columns.map((v) => {
            if (v?.dataIndex == targetColumn) {
                v['title'] = `${v?.title} (${policy}) `;
            }
            return v;
        })
    }

    function fileDelete() {
        upload.setDelFile({
            delete: async (file) => {
                table.setData([]);
                tableWithErrors.setData([]);
                rejectedList.setData([]);
                setResult([]);
                setErrors([]);
            }
        });
    }

    async function saveBatchResult() {
        const { acad_year, course_id, semester, session, program } = add.record;
        if (acad_year == undefined || course_id == undefined || semester == undefined || session == undefined || program == undefined) {
            Utilities.showNotification('Attention', 'All form fields are mandatory');
            return;
        }
        if (result.length <= 0) {
            Utilities.showNotification('Attention', 'No result data found');
            return;
        }
        let res = await utils.requestWithReauth('post', `${Settings.backend}/save_extra_batch_results`, null, { form: add.record, result });
        if (res?.status == "Ok") {
            //show rejected list here
            utils.showNotification(undefined, 'Operation successful', 'text-success');
            rejectedList.setData(res?.failedList);
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }

    async function saveBatchResultGeneral() {
        const { acad_year, course_id, semester } = add.record;
        if (acad_year == undefined || course_id == undefined || semester == undefined) {
            Utilities.showNotification('Attention', 'All form fields are mandatory');
            return;
        }
        if (result.length <= 0) {
            Utilities.showNotification('Attention', 'No result data found');
            return;
        }
        let res = await utils.requestWithReauth('post', `${Settings.backend}/save_extra_batch_results_general`, null, { form: add.record, result });
        if (res?.status == "Ok") {
            //show rejected list here
            utils.showNotification(undefined, 'Operation successful', 'text-success');
            rejectedList.setData(res?.failedList);
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }

    const rejectedListColumns = (
        [
            {
                title: 'Index Number',
                dataIndex: 'IndexNumber',
                ...table.getColumnSearchProps('IndexNumber'),
            },
            {
                title: `Reason`,
                dataIndex: 'reason',
                render(v, data) {
                    return <label className='text-danger'>{v}</label>
                }
            },
            {
                title: `Assessment`,
                dataIndex: 'Assessment',
            },
            {
                title: `Exams`,
                dataIndex: 'Exams',
            },
            {
                title: `Course`,
                dataIndex: 'Course',
            },
            {
                title: `AcadYr.`,
                dataIndex: 'AcadYr',
            },
            {
                title: `Semeseter`,
                dataIndex: 'Semeseter',
            },
            {
                title: `Session`,
                dataIndex: 'Session',
            }
        ]);

    useMemo(async () => {
        gradeChangeSubmit();
        straightUpload();
        fileDelete();
        upload.setNumFiles(1);
        upload.setAcceptedFiles(['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']);
        genForm();

        const mlp = await getMarkAllocationPolicy();
        appendMarksPolicyToTableHeading(columns, 'Assessment', mlp?.internal);
        appendMarksPolicyToTableHeading(columns, 'Exams', mlp?.external);
        table.setColumns(columns);

        appendMarksPolicyToTableHeading(columnsWithErrors, 'Assessment', mlp?.internal);
        appendMarksPolicyToTableHeading(columnsWithErrors, 'Exams', mlp?.external);
        tableWithErrors.setColumns(columnsWithErrors);
        rejectedList.setColumns(rejectedListColumns);

        console.log('looping')

    }, [dynamicForm.data]);

    // 5230300001


    function theTabs() {
        return [
            {
                label: <><label className='d-none d-md-flex'>Single Student</label></>,
                key: 'single_student',
                children: singleStudent()
            },
            {
                label: <><label className='d-none d-md-flex'>Upload List</label></>,
                key: 'upload_list',
                children: uploadList(saveBatchResult)
            },
            {
                label: <><label className='d-none d-md-flex'>Upload General List</label></>,
                key: 'upload_list_general',
                children: uploadList(saveBatchResultGeneral)
            }
        ];
    }

    function singleStudent() {
        return <div className='row mt-3'>
            <div className='col-md-12'>
                <div className='d-flex'>
                    <div className='d-flex mb-2x me-2'>
                        <Input onPressEnter={e => queryExtraGrade()} addonBefore={<><i className='fas fa-user' /> Index Number</>} className='me-2' value={indexNo} onChange={e => setIndexNo(e.target.value)} />
                        <Input onPressEnter={e => queryExtraGrade()} addonBefore={<><i className='fas fa-file' /> Course Code </>} className='' value={courseCode} onChange={e => setCourseCode(e.target.value)} />
                    </div>
                    <div className='d-flex'>
                        <Button onClick={e => queryExtraGrade()} className='btn-success border-0 me-2'> <i className='me-2 fas fa-paper-plane' /> Query</Button>
                    </div>
                </div>
            </div>
            <div className='col-md-4 mt-3'>
                <Card className='border-0'>
                    {
                        Object.keys(grade)?.length > 1 ?
                            <div className='rgba-blue-light p-3 rounded'>
                                <div>Course Code: {grade?.course_id}</div>
                                <div>Course Name: {grade?.course_name}</div>
                                <div>Semester: {grade?.semester_id}</div>
                                <div>Status: {grade?.status}</div>
                                <div>Assessment: {grade?.assessment}</div>
                                <div>Exams: {grade?.exams}</div>
                                <div>Total Score: {grade?.total_obtained_marks}</div>
                                <div className='text-danger fw-bold'>Grade : {grade?.grade}</div>
                                <Space>
                                    <div className='mt-2'><Button onClick={e => extraGradeChangeRequest('grade_change')} className='btn-success border-0' icon={<i className='fas fa-edit me-1' />}>Grade Change</Button></div>
                                    {/* Only show Resit button when grade is E */}
                                    {grade?.grade == 'E' && <div className='mt-2'><Button onClick={e => extraGradeChangeRequest('resit')} className='btn-primary border-0' icon={<i className='fas fa-edit me-1' />}>Resit</Button></div>}
                                    {del.confirm(
                                        `${Settings.backend}/delete`,
                                        { id: grade?.id },
                                        'Are you sure to delete this item',
                                        { tableName: 'scoresheet_summary_extra', where: `id`, whereType: 'closed' },
                                        <Button className='btn-danger border-0 mt-2' icon={<i className='fas fa-trash me-1' />}>Drop</Button>,
                                        undefined, undefined, undefined
                                    )}
                                </Space>
                            </div> :
                            <div className='p-3'>
                                <Empty description="No course found" />
                                {Object.keys(studentDetails).length > 1 ? <Button onClick={e => extraGradeAddRequest('add_grade')} className='btn-success border mt-2' icon={<i className='fas fa-plus me-1' />}>Add Grade</Button> : ''}
                            </div>
                    }
                </Card>

            </div>

            <div className='col-md-4 mt-3'>
                {
                    Object.keys(studentDetails).length > 1 ? <Card className='border-0'>
                        <div className='mdb-color text-white p-3 rounded'>
                            <div>First Name: {studentDetails?.fname}</div>
                            <div>Last Name: {studentDetails?.lname}</div>
                            <div>Middle Name: {studentDetails?.mname || 'N/A'}</div>
                            <div>Session: {studentDetails?.session}</div>
                            <div>Program: {studentDetails?.short_name}</div>
                        </div>
                    </Card> : <Empty description="No student data found" />
                }
            </div>


        </div>
    }

    function downloadTemplate() {
        excel.exportXLSX(preferredHeadings, [], 'results_template', `results_template_${new Date().getDate()}.xlsx`);
    }

    function downloadRejectedList() {
        const cols = ['IndexNumber', 'Assessment', 'Exams', 'Course', 'Semester', 'AcadYr', 'Program', 'Session', 'Reason'];
        excel.exportXLSX(cols, rejectedList?.data, 'rejected_list', `rejected_list_${new Date().getDate()}.xlsx`);
    }


    function uploadList(saveBatchResult) {
        return <div className='rowx'>
            <div className='row'>
                <div className='col-md-6'>
                    {add.form}
                </div>
                <div className='col-md-6'>
                    <label className='text-primary fw-bold'>Rejected list after save operation will appear below</label>
                    <Button className='ms-2' onClick={e => downloadRejectedList()} icon={<i className='fas fa-download me-1' />}>Download</Button>
                    <div className='table-responsive h-scrolling-wrapper mt-1'>
                        {rejectedList.table}
                    </div>
                </div>
            </div>
            <div className='col-md-4x mt-2'>
                {upload.uploader('fileUpload', '', 'uploadMap', ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'])}
            </div>
            <div className='col-md-4x'>
                <Button onClick={e => saveBatchResult()} className='btn-success border-0 me-2'> <i className='me-2 fas fa-save' /> Save</Button>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    {table.tableWithHeader(() => <label className='text-primary fw-bold'>Total records without errors {result.length}</label>)}
                </div>
                <div className='col-md-8'>
                    {tableWithErrors.tableWithHeader(() => <label className='text-danger fw-bold'>Total records with errors {errors.length}</label>)}
                </div>
            </div>
        </div>
    }



    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <Card
                    bordered={false}
                    className="criclebox tablespace border-0 mb-24 p-2"
                    title="Upload Marks & Grades"
                    extra={<Button onClick={downloadTemplate} className='btn-primary border-0' icon={<i className='fas fa-download me-1' />}>Download Template</Button>}
                >
                    <Tabs
                        // tabPosition={'left'}
                        items={theTabs()}
                    />
                </Card>

            </div>
            {dynamicForm.formModal(null, null)}
            {/* {edit.editModal('Edit Item', editOnOk)} */}
            {/* {add.addModal('Add New Item', addOnOk)} */}
        </>
    );
}

export default memo(ExtraGradeCorrections);