

import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Card } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';

import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import { confirmAlert } from 'react-confirm-alert';
import CustomFunctions from '../../dependencies/custom/custom-functions';
const GradeChangeRequestGeneral = (props) => {
    const valuesStore = ValuesStore();
    // const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    // const add = useAdd('tables_metadata', 'table_name');
    // const del = useDelete();
    const extraFiltersAddMeta = 'extraFiltersAddMeta';
    const extraFiltersAdd = useAdd(extraFiltersAddMeta, 'table_name');
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    const [btnPermissions, setBtnPermissions] = useState({});
    const [forceRenderer, setForceRenderer] = useState(false);
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'grade_change_general', fields: ['*'] });



    const columns = ([
        {
            title: '',
            dataIndex: 'status',
            filters: [
                {
                    text: 'Submitting',
                    value: 'submitting'
                },
                {
                    text: 'Approved',
                    value: 'approved'
                },
                {
                    text: 'Declined',
                    value: 'declined'
                }
            ],
            render: (v, r) => {
                return <>
                    {r.status === 'approved' ? <i className='fas fa-check-double text-success' /> : r.status === 'declined' ? <i className='fas fa-times text-danger' /> : <i className='fas fa-spinner text-warning' />}
                </>
            }
        },
        {
            title: 'IndexNo.',
            dataIndex: 'student_id',
            ...table.getColumnSearchProps('student_id'),
        },
        {
            title: 'NewMarks',
            dataIndex: 'totalNewMark',
        },
        {
            title: 'OldMarks',
            dataIndex: 'totalOldMark',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks'
        },
        {
            title: 'Lecturer',
            dataIndex: 'submitted_by',
            filterSearch: true,
            render(val, record) {
                return `${val}(${record.adminName})`;
            }
        },
        {
            title: 'CourseCode',
            dataIndex: 'course_code',
            ...table.getColumnSearchProps('course_code'),
            render: (_, record) => {
                return `${record.course_name}(${record.course_code})`
            }
        }
    ]);

    if (btnPermissions?.show_grade_change_buttons) {
        columns.push({
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-successx border-0x' onClick={e => onSetGradeChangeStatus(record, 'approve', 'single')}><i className='fas fa-thumbs-up text-success' /></Button>
                    <Button className='btn-successx border-0x' onClick={e => onSetGradeChangeStatus(record, 'decline', 'single')}><i className='fas fa-thumbs-down text-danger' /></Button>
                </Space>
            },
        });
    }

    function setupExtraFilters() {
        setTimeout(e => {
            valuesStore.setValue(extraFiltersAddMeta, [
                {
                    id: '', table_name: 'grade_change_filters',
                    column_name: 'type', col_real_name: 'Type', type: 'jsonMultiSelect',
                    options: '{"grade_change":"Grade Change","resit":"Resit"}',
                    extra_options: '',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                // {
                //     id: '', table_name: 'grade_change_filters',
                //     column_name: 'program', col_real_name: 'Program', type: 'sqlMultiSelect',
                //     options: '{"sql":"SELECT name,alias FROM programs","key":"alias","value":"name"}',
                //     extra_options: ``,
                //     backend_visible: 1, front_visible: 0,
                //     editable: 1, validator: '', icon: '', rank: 3
                // }
            ]);
            extraFiltersAdd.setTblName('grade_change_filters');
        }, 1000);
    }

    const colFilters = [
        {
            filter: 'submitted_by',
            sql: 'SELECT staff_id,name FROM admin',
            key: 'staff_id',
            value: 'name,staff_id'
        }
    ];


    async function onSetGradeChangeStatus(record, status, type) {
        confirmAlert({
            title: 'Confim grade change request',
            message: 'Action cannot be undone Click on Yes to continue. Click on No stop',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { setGradeStatus(record, status, type) }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    function setGradeStatus(record, status, type) {
        switch (status) {
            case "approve": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.setGradeStatusGeneral(table.selectedRows, 'approve', setForceRenderer);
                    } else {
                        utils.showNotification(undefined, 'No selection was done');
                    }
                } else {
                    CustomFunctions.setGradeStatusGeneral([record], 'approve', setForceRenderer);
                }

                break;
            }
            case "decline": {
                if (type === 'multiple') {
                    if (table.selectedRows.length) {
                        CustomFunctions.setGradeStatusGeneral(table.selectedRows, 'decline', setForceRenderer);
                    } else {
                        utils.showNotification(undefined, 'No selection was done');
                    }
                } else {
                    CustomFunctions.setGradeStatusGeneral([record], 'decline', setForceRenderer);
                }
                break;
            }
        }
    }

    function applyFilter() {
        table.rowSelectionDebug();
        table.setSelectedRows([]);
        if (extraFiltersAdd.record?.type) {
            let values = extraFiltersAdd.record?.type.split(',').map(v => v);
            filters['grade_change_general.type'] = values;
        }
        // if (extraFiltersAdd.record?.program) {
        //     let values = extraFiltersAdd.record?.program.split(',').map(v => v);
        //     filters['name'] = values;
        // }

        table.setTableParams(r => {
            return { ...r, filters: { ...r.filters, ...filters } };
        });

        //this is fixing a bug
        if (Object.keys(filters) <= 0) {
            table.setTableParams(r => ({ ...r, filters }));
        }
        if (dateRange?.length) {
            let startDate = dateRange[0]?.$d;
            let endDate = dateRange[1]?.$d;
            if (startDate && endDate) {
                utils.filterByDate('grade_change_general.date_inserted', dateRange, table);
            }
        } else {
            table.setExtraFetchParams({});
        }
    }

    async function setTableDataURL() {
        //the permissions are arranged from max to min. Max perms have the highest priority
        const permList = [
            'get_grade_change_requests_general',
            'get_submitted_grade_change_requests_general',
        ];
        for (let i = 0; i < permList.length; i++) {
            const v = permList[i];
            const hasPerm = await utils.hasPermission(v, null, valuesStore);
            if (hasPerm) {
                table.setDataURL(`${Settings.backend}/${v}`);
                break;
            }
        }
    }

    useMemo(() => {
        console.log('looping');
        setTableDataURL();
        applyFilter();
        setupExtraFilters();
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);

        if (btnPermissions?.show_grade_change_buttons) {
            table.setAllowSelection(true);
        }

        showBtnUsingPermissions();
    }, [/*extraFiltersAdd.record*/, forceRenderer, JSON.stringify(table.extraFetchParams), btnPermissions]);


    function showBtnUsingPermissions() {
        if (Object.keys(btnPermissions) <= 0) {
            const permList = ['show_grade_change_buttons'];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v, null, valuesStore);
                setBtnPermissions(r => ({ ...r, [v]: perm }))
            });
            table.rowSelectionDebug();
        }
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <Card
                    bordered={false}
                    className="criclebox tablespace border-0 mb-24 p-2"
                    title="Grade Change Requests"
                    extra={
                        <Space>
                            <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} />
                            <Button className='btn-primary border-0' onClick={e => applyFilter()}><i className='fas fa-filter me-2' />Filter</Button>
                            <Button className='btn-info border-0' onClick={e => navigate(0)}>Clear All Filters</Button>
                            {btnPermissions?.show_grade_change_buttons && <Button className='btn-success border-0' onClick={e => onSetGradeChangeStatus(null, 'approve', 'multiple')}><i className='fas fa-check-double me-2' /> Approve All</Button>}
                            {btnPermissions?.show_grade_change_buttons && <Button className='btn-danger border-0' onClick={e => onSetGradeChangeStatus(null, 'decline', 'multiple')}><i className='fas fa-times me-2' /> Decline All</Button>}
                        </Space>
                    }
                >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='mt-2'>
                                {extraFiltersAdd.form}
                            </div>
                        </div>
                        <div className='col-md-12 mt-2'>
                            {table.table}
                        </div>
                    </div>
                </Card>

            </div>
        </>
    );
}

export default memo(GradeChangeRequestGeneral);