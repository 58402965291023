
import React, { useState, memo, useMemo } from 'react';
import ValuesStore from '../../store/values-store';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Card,Tag,Tooltip } from 'antd';
import useAdd from '../../hooks/add';

import useTable from '../../hooks/table';
import Settings from '../../dependencies/custom/settings';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import { useNavigate } from 'react-router-dom';

const CourseEvaluation = (props) => {
    const valuesStore = ValuesStore();
    const extraFiltersAddMeta = 'extraFiltersAddMeta';
    const add = useAdd('tables_metadata', 'table_name');
    const extraFiltersAdd = useAdd(extraFiltersAddMeta, 'table_name');
    const navigate = useNavigate();
    const { filters, filterTypes } = utils.generateTableFilters();
    

    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight', 'topRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_assigned_courses_root`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'course_lecturer', fields: ['*'] }, true);

    const columns = ([
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    {/* {btnPermissions?.show_goto_sheet_button && <Tooltip title="View sheet">
                        <Button className='btn-primaryx border-0x' onClick={e => gotoSheet(record)}><i className='fas fa-eye text-primary' /></Button>
                    </Tooltip>} */}
                    <Tooltip title="View evaluation">
                        <Button className='btn-primaryx border-0x' onClick={e => gotoEvaluation(record)}><i className='fas fa-chart-bar text-primary' /></Button>
                    </Tooltip>
                </Space>
            },
        },
        {
            title: '',
            dataIndex: 'mounted_for',
            render: (v, r) => {
                if (r.mounted_under) {
                    return <>
                        <Tag color={'blue'} className={`rounded`}>{'normal'}</Tag>
                        {r.status === 'published' ? <i className='fas fa-check-double text-success' /> : r.status === 'approved' ? <i className='far fa-check-circle text-warning' /> : <i className='fas fa-spinner text-danger' />}

                    </>
                } else {
                    return <>
                        <Tag color={v === 'normal' ? 'Green' : 'Red'} className={`rounded`}>{v}</Tag>
                        {r.status === 'published' ? <i className='fas fa-check-double text-success' /> : r.status === 'approved' ? <i className='far fa-check-circle text-warning' /> : <i className='fas fa-spinner text-danger' />}
                    </>
                }
            }
        },
        {
            title: 'Course',
            dataIndex: 'course_code',
            ...table.getColumnSearchProps('course_code'),
            // filterSearch: true,
            render: (_, record) => {
                return `${record.course_code}(${record.course_name})`
            }
        },
        {
            title: 'AcadYr',
            dataIndex: 'acad_year',
            ...table.getColumnSearchProps('acad_year'),
            // filteredValue: table.filteredInfo.acad_year || null,
        },
        {
            title: 'Level',
            filterSearch: true,
            dataIndex: 'mounted_level',
        },
        {
            title: 'Session',
            dataIndex: 'session',
            // render: (_, record) => {
            //     return <a className='blue-text' onClick={e => navigate(`./ad_details?advert_id=${record['custom_id']}#page=5`)}><i className='fas fa-user' /></a>
            // },
        },
        {
            title: 'Semester',
            filterSearch: true,
            dataIndex: 'semester_id',
        },
        {
            title: 'Program',
            dataIndex: 'program',
            // ...table.getColumnSearchProps('program'),
            filterSearch: true,
            // render: (_, record) => {
            //     return `${record.course_code}(${record.course_name})`
            // }
        },
        {
            title: 'Lecturer',
            dataIndex: 'lecturer',
            ...table.getColumnSearchProps('lecturer'),
            render: (_, record) => {
                return `${record.lecturer}(${record.lecturer_id})`
            }
        },
    ]);

    const colFilters = [
        {
            filter: 'session',
            sql: "SELECT session,alias FROM sessions",
            key: 'alias',
            value: 'session'
        },
        {
            filter: 'mounted_level',
            sql: "SELECT level FROM level",
            key: 'level',
            value: 'level'
        },       
        {
            filter: 'program',
            sql: "SELECT name,alias FROM programs WHERE show_hide =1 ORDER BY name ",
            key: 'alias',
            value: 'name'
        },
        {
            filter: 'semester_id',
            sql: 'SELECT semester,alias FROM semester',
            key: 'alias',
            value: 'semester'
        },
        {
            filter: 'mount_type',
            sql: 'SELECT distinct mount_type FROM course_lecturer',
            key: 'mount_type',
            value: 'mount_type'
        }
    ];

    function gotoEvaluation(course){        
        navigate('../evaluation', { state: course});          
    }


    function setupExtraFilters() {
        setTimeout(e => {
            valuesStore.setValue(extraFiltersAddMeta, [
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'assigned_by', col_real_name: 'Assign By', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT staff_id,name FROM admin","key":"staff_id","value":"staff_id,name"}',
                    extra_options: '',
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 1
                },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'submitted', col_real_name: 'Submitted', type: 'jsonMultiSelect',
                    options: '{"1":"Yes","0":"No"}',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 2
                },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'mounted_for', col_real_name: 'Category', type: 'csvMultiSelect',
                    options: 'normal,resit,ic',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 3
                },
                {
                    id: '', table_name: 'course_assignment_filters',
                    column_name: 'status', col_real_name: 'Status', type: 'csvMultiSelect',
                    options: 'submitting,approved,published',
                    extra_options: ``,
                    backend_visible: 1, front_visible: 0,
                    editable: 1, validator: '', icon: '', rank: 3
                }
            ]);
            extraFiltersAdd.setTblName('course_assignment_filters');
        }, 1000);
    }


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        // setupExtraFilters();
        console.log('looping')

    }, [add.saveCompleted]);



    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <Card
                    bordered={false}
                    className="criclebox tablespace border-0 mb-24 p-2"
                    title="Course Evaluation"
                    extra={
                        <Space className='d-flexx'>
                            {/* <Button className='btn-primary' onClick={e => fetchEvaluation()}><i className='fas fa-filter me-2' />Fetch</Button> */}
                        </Space>
                    }
                >
                    <div className='row'>
                        <div className='col-md-12 mb-2'>
                            <div className='mt-2'>
                                <div className='row row-cols-2'>
                                    {/* {extraFiltersAdd?.form?.map((v, i) => <div key={i}>{v}</div>)} */}
                                </div>
                            </div>
                        </div>                                            
                        <div className='col-md-12'>
                            <div className='table-responsive h-scrolling-wrapper'>
                                {table.table}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default memo(CourseEvaluation);