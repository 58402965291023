
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, Tag, Card } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const AcadCalendar = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [jobHasRun, setJobHasRun] = useState(false);
    const navigate = useNavigate();
    const keyOverrides = { categoryAlias: 'category' };
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'acad_calendar', fields: ['*'] });


    const columns = ([
        {
            title: 'ID',
            dataIndex: 'id',
            ...table.getColumnSearchProps('id'),
        },
        {
            title: 'Batch',
            dataIndex: 'student_batch',
            ...table.getColumnSearchProps('student_batch'),
            // render:(v,record)=>{
            //     return <a className='blue-text' onClick={e=>navigate(`../students?batch=${v}#page=19`)}>{v}</a>
            // }
        },
        {
            title: 'NxtAcadYr',
            dataIndex: 'next_acadyr',
            ...table.getColumnSearchProps('next_acadyr'),
        },
        {
            title: 'NxtSem',
            dataIndex: 'next_semester',
            ...table.getColumnSearchProps('next_semester'),
        },
        {
            title: 'NxtLvl',
            dataIndex: 'next_level',
            ...table.getColumnSearchProps('next_level'),
        },
        {
            title: 'NxtStat',
            dataIndex: 'next_status',
            ...table.getColumnSearchProps('next_status'),
        },
        {
            title: 'NxtCalID',
            dataIndex: 'next_calendar',
            ...table.getColumnSearchProps('next_calendar'),
        },
        {
            title: 'TriggerDate',
            dataIndex: 'trigger_date',
            ...table.getColumnSearchProps('trigger_date'),
            render: (v) => `${utils.formatDate(v)}`
        },
        {
            title: 'Active',
            dataIndex: 'is_active',
            filters: [
                {
                    text: 'Yes',
                    value: '1'
                },
                {
                    text: 'No',
                    value: '0'
                }
            ],
            render: (v, r) => v ? <Tag color={'green-inverse'}>Yes</Tag> : <Tag color={'blue-inverse'}>No</Tag>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-secondary border-0' onClick={e => runJob(record)}><i className='fas fa-play me-1' /> Run</Button>
                    <Button onClick={e => editRecord(record, 'acad_calendar')}><i className='fas fa-edit text-success' /></Button>
                    {del.confirm(
                        `${Settings.backend}/delete`,
                        record,
                        'Are you sure to delete this item',
                        { tableName: 'acad_calendar', where: 'id', whereType: 'closed' },
                        <Button>  <i className='fas fa-trash text-danger' /></Button>
                    )}
                </Space>
            },
        },
    ]);

    const colFilters = [
        // {
        //     filter: 'next_acadyr',
        //     sql: 'SELECT id,acadyr FROM acad_calendar',
        //     key: 'id',
        //     value: 'acadyr'
        // }
    ];

    async function runJob(record) {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/run_job`, null, { job: record });
        if (res.status == 'Ok') {
            utils.showNotification(undefined, 'Operation successful');
            setJobHasRun(!jobHasRun);
        } else {
            utils.showNotification(undefined, res.msg);
        }
    }

    function editRecord(record, tableName) {
        utils.renameKeys(record, keyOverrides);
        const storeKey = 'editableRecord';
        valuesStore.setValue(storeKey, record);
        edit.setTblName(tableName);
        edit.setData(record);
        edit.setRecordKey(storeKey);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);
    }

    function addRecord(tableName = 'acad_calendar') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'acad_calendar',
            { tbl: 'acad_calendar', where: 'id', whereType: 'closed' },
        );
    }

    async function addOnOk() {
        let res = await add.save(`${Settings.backend}/add`, { tbl: 'acad_calendar' })
    }


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams, jobHasRun]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <Card
                    bordered={false}
                    className="criclebox tablespace border-0 mb-24 p-2"
                    title="Jobs"
                    extra={
                        <Space>
                            <Button className='btn-primary border-0' onClick={e => addRecord()}><i className='fas fa-user-plus me-2' /> Add New Item</Button>
                        </Space>
                    }
                >
                    <div className='row'>
                        <div className='col-md-12'>
                            {table.table}
                        </div>
                    </div>
                </Card>
            </div>
            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)}
        </>
    );
}

export default memo(AcadCalendar);